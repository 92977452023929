





































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    suffix() {
      return this.isCN ? "cn" : "en";
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cyberboard_xmas);
    }
  }
});
